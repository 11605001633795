<template>
    <span>
        <b-card-actions title="Filters" action-collapse>
      <div>
        <!-- search input -->
        <div>
            <b-row>

              <b-col
                  md="6"
                  lg="4"
                  xs="12"
              >
                <v-select
                    v-model="filterSelectEmployeeById"
                    :options="filterEmployeeByOption"
                    :reduce="(item) => item.id"
                    label="name"
                    placeholder="Action Taken By"
                    class="mb-1 custom-font"
                    @input="loadItems"
                >
                  <template #option="data">
                    <UserSelect :user="data" />
                  </template>
                </v-select>
              </b-col>

              <b-col
                  md="6"
                  lg="4"
                  xs="12"
              >
                <v-select
                    v-model="filterSeller"
                    :options="filterSellerByOption"
                    :reduce="(item) => item.name"
                    label="name"
                    placeholder="By Seller"
                    class="mb-1 custom-font"
                    @input="loadItems"
                >
                </v-select>
              </b-col>

              <b-col
                  md="6"
                  lg="4"
                  xs="12"
              >
                <v-select
                    v-model="filterSiteList"
                    :options="filterSiteListByOption"
                    :reduce="(option) => option.website"
                    label="website"
                    placeholder="By Site List"
                    class="mb-1 custom-font"
                    @input="loadItems"
                >
                </v-select>
              </b-col>

            </b-row>
          </div>
      </div>
    </b-card-actions>

     <b-card style="max-height: 600px; overflow-y: auto">
        <app-timeline>
        <template v-for="row in rows">
            <!-- Display created items first -->
            <app-timeline-item
                :key="row.id"
                v-if="row.event === 'created'"
            >
                <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                    <h6 v-html="formatMsg(row.log_name, row.properties, row.event)"></h6>
                <small class="text-muted">Created: {{ formatDate(row.created_at) }}</small>
                </div>
            </app-timeline-item>
        
            <!-- Display updated items with old and new values -->
            <app-timeline-item
                :key="row.id + '-updated'"
                v-if="row.event === 'updated'"
                variant="warning"
            >
                <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                    <h6 v-html="formatMsg(row.log_name, row.properties, row.event)"></h6>
                <small class="text-muted">Updated: {{ formatDate(row.created_at) }}</small>
                </div>
        
                <b-button
                v-b-toggle="'report-list-' + row.id"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                size="sm"
                variant="outline-primary"
                >
                Show Report
                </b-button>
                <b-collapse :id="'report-list-' + row.id">
                <b-list-group flush class="mt-1">
                    <b-list-group-item
                    href="#"
                    class="d-flex justify-content-between align-items-center bg-transparent"
                    >
                    <ul>
                        <li v-for="(oldValue, key) in row.properties.changes.old" :key="'old-' + key">
                            <div v-html="formatUpdatedValues(key, oldValue, row.properties.changes.new[key], row.log_name)"></div>
                        <!-- <strong>{{ key }}:</strong> Old: {{ oldValue }} &rarr; <strong> New: {{ row.properties.changes.new[key] }}</strong> -->
                        </li>
                    </ul>
                    </b-list-group-item>
                    <!-- Add more report data here if needed -->
                </b-list-group>
                </b-collapse>
            </app-timeline-item>
        </template>
    </app-timeline>
     </b-card>
    </span>
  </template>
  
  
  <script>
  import {
    BImg, BAvatar, BMedia, BButton, BCollapse, VBToggle, BListGroup, BListGroupItem, BAvatarGroup, BBadge, VBTooltip, BCard, BRow, BCol,
  } from 'bootstrap-vue'
  import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
  import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
  import Ripple from 'vue-ripple-directive'
  import UserSelect from "@/layouts/components/UserSelect.vue";
  import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
  import {
    OUTREACH_LOGS_ACCESS,
    OUTREACH_LOGS_SHOW,
} from "@/helpers/permissionsConstant";
  
  export default {
    components: {
        BCardActions,
        UserSelect,
      AppTimeline,
      AppTimelineItem,
      BImg,
      BMedia,
      BAvatar,
      BButton,
      BCollapse,
      BListGroup,
      BListGroupItem,
      BAvatarGroup,
      BBadge,
      BCard,
      BRow,
      BCol,
    },
    directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },

    data() {
      return {

        OUTREACH_LOGS_ACCESS,
        OUTREACH_LOGS_SHOW,

        rows: [],
        filteredCurrencyOptions: [],
        filterEmployeeByOption: [],
        filterSelectEmployeeById: "",
        filterSeller: "",
        filterSellerByOption: [],
        filterSiteList: "",
        filterSiteListByOption: [],
        
    }
    },

    async created() {
      try {
        await this.getAllCurrencies();
        await this.getAllEmployee();
        await this.getAllSellers();
        await this.getFilteredSiteListOptions();
        this.loadItems();

      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "BellIcon",
            variant: "danger",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    methods: {

      async getFilteredSiteListOptions() {
      try {
        const response = await this.$api.get("/api/outreach/site-list/all/approved");

        this.filterSiteListByOption = (response.data.data || []).map((item) => {
          let website = item.website;
          return {
            id: item.id,
            website,
          };
        });

      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "BellIcon",
            variant: "danger",
            text: error?.response?.data?.message,
          },
        });
      }
    },

      async getAllSellers() {
      try {
        const response = await this.$api.get("api/outreach/seller/all");

        this.filterSellerByOption = (response?.data?.data || []).map((item) => {
          let name = item.name;
          return {
            name,
            id: item.id,
          };
        });
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "BellIcon",
            variant: "danger",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    async getAllEmployee() {
      try {
        const response = await this.$api.get("api/users/active-all");
        
        this.filterEmployeeByOption = (response?.data?.data || []).map(
            item => ({
              avatar: item?.avatar,
              name: item?.name,
              email: item?.email,
              mobile: item?.mobile,
              employee_number: item?.employee_number,
              id: item?.id,
              departmentId: item?.department_id,
            }),
        );
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "BellIcon",
            variant: "danger",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    async getAllCurrencies() {
      try {
        const response = await this.$api.get("api/currencies/all");

        this.filteredCurrencyOptions = (response?.data?.data || []).map((item) => {
          let name = item.name;
          return {
            name,
            currency: item.currency,
            id: item.id,
          };
        });
        
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "BellIcon",
            variant: "danger",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    formatMsg(log_name, properties, event) {
        if (log_name === 'sitelist' && event === 'created') {
            return `<span class="font-weight-bolder text-primary">${properties?.attributes?.creator_name} </span> ${event} a new site list: <span class="font-weight-bolder text-primary">${properties?.attributes?.website}</span> under seller: <span class="font-weight-bolder text-primary">${properties?.attributes?.seller_name} </span>`;
        }else if (log_name === 'sitelist' && event === 'updated') {
            return `<span class="font-weight-bolder text-warning">${properties?.attributes?.updator_name} </span> ${event} a new site list: <span class="font-weight-bolder text-warning">${properties?.attributes?.website}</span> under seller: <span class="font-weight-bolder text-warning">${properties?.attributes?.seller_name} </span>`;
        }else if (log_name === 'backlink' && event === 'created') {
            return `<span class="font-weight-bolder text-primary">${properties?.attributes?.creator_name} </span> ${event} a new back link: <span class="font-weight-bolder text-primary">${properties?.attributes?.backlink_url}</span> under site list: <span class="font-weight-bolder text-primary">${properties?.attributes?.site_list_name} </span> under seller name: <span class="font-weight-bolder text-primary">${properties?.attributes?.seller_name} </span>`;
        }else if (log_name === 'backlink' && event === 'updated') {
            return `<span class="font-weight-bolder text-warning">${properties?.attributes?.updator_name} </span> ${event} a new back link: <span class="font-weight-bolder text-warning">${properties?.attributes?.backlink_url}</span> under site list: <span class="font-weight-bolder text-warning">${properties?.attributes?.site_list_name} </span> under seller name: <span class="font-weight-bolder text-warning">${properties?.attributes?.seller_name} </span>`;
        }else if (log_name === 'outreach_invoice' && event === 'created') {
            return `<span class="font-weight-bolder text-primary">${properties?.attributes?.creator_name} </span> ${event} a new outreach invoice: <span class="font-weight-bolder text-primary">${properties?.attributes?.invoice_number}</span> under backlink: <span class="font-weight-bolder text-primary">${properties?.attributes?.backlink_url} </span> and seller: <span class="font-weight-bolder text-primary">${properties?.attributes?.seller_name} </span>`;
        }else if (log_name === 'outreach_invoice' && event === 'updated') {
            return `<span class="font-weight-bolder text-warning">${properties?.attributes?.creator_name} </span> ${event} a new outreach invoice: <span class="font-weight-bolder text-warning">${properties?.attributes?.invoice_number}</span> under backlink: <span class="font-weight-bolder text-warning">${properties?.attributes?.backlink_url} </span> and seller: <span class="font-weight-bolder text-warning">${properties?.attributes?.seller_name} </span>`;
        }
    },

    formatUpdatedValues(key, oldValue, newValue, log_name) {
        if (key === 'website') {
            const formattedKey = 'Website';
            return `<strong>${formattedKey}:</strong> Old: ${oldValue} &rarr; <strong> New: ${newValue}</strong>`;
        }else if (key === 'nich_id') {
            const formattedKey = 'Niche';
            return `<strong>${formattedKey}:</strong> has changed`;
            
        }else if (key === 'seller_id') {
            const formattedKey = 'Seller';
            return `<strong>${formattedKey}:</strong> has changed`;
    
        }else if (key === 'traffic') {
            const formattedKey = 'Traffic';
            return `<strong>${formattedKey}:</strong> Old: ${oldValue} &rarr; <strong> New: ${newValue}</strong>`;

        }else if (key === 'traffic_value') {
            const formattedKey = 'Traffic Value';
            return `<strong>${formattedKey}:</strong> Old: ${oldValue} &rarr; <strong> New: ${newValue}</strong>`;

        }else if (key === 'traffic_value_currency') {
            const formattedKey = 'Traffic Value Currency';

            const oldCurrency = this.filteredCurrencyOptions.find(item => item.id === oldValue)?.currency || 'Unknown';
            const newCurrency = this.filteredCurrencyOptions.find(item => item.id === newValue)?.currency || 'Unknown';

            return `<strong>${formattedKey}:</strong> Old: ${oldCurrency} &rarr; <strong> New: ${newCurrency}</strong>`;

        }else if (key === 'spam_score') {
            const formattedKey = 'Spam Score';
            return `<strong>${formattedKey}:</strong> Old: ${oldValue} &rarr; <strong> New: ${newValue}</strong>`;

        }else if (key === 'post_price') {
            const formattedKey = 'Post Price';
            return `<strong>${formattedKey}:</strong> Old: ${oldValue} &rarr; <strong> New: ${newValue}</strong>`;

        }else if (key === 'post_price_currency') {
            const formattedKey = 'Post Price Currency';

            const oldCurrency = this.filteredCurrencyOptions.find(item => item.id === oldValue)?.currency || 'Unknown';
            const newCurrency = this.filteredCurrencyOptions.find(item => item.id === newValue)?.currency || 'Unknown';

            return `<strong>${formattedKey}:</strong> Old: ${oldCurrency} &rarr; <strong> New: ${newCurrency}</strong>`;

        }else if (key === 'domain_authority') {
            const formattedKey = 'Domain Authority';
            return `<strong>${formattedKey}:</strong> Old: ${oldValue} &rarr; <strong> New: ${newValue}</strong>`;

        }else if (key === 'referal_domain') {
            const formattedKey = 'Referal Domain';
            return `<strong>${formattedKey}:</strong> Old: ${oldValue} &rarr; <strong> New: ${newValue}</strong>`;

        }else if (key === 'project_id') {
            const formattedKey = 'Project ID';
            return `<strong>${formattedKey}:</strong> has changed`;

        }else if (key === 'approved_for') {
            const formattedKey = 'Approved For';

            const approvedForOptions = [
                { id: 0, name: "White Niche" },
                { id: 1, name: "Gray Niche" },
            ];

            const oldStatus = approvedForOptions.find(option => option.id === oldValue)?.name || oldValue;
            const newStatus = approvedForOptions.find(option => option.id === newValue)?.name || newValue;

            return `<strong>${formattedKey}:</strong> Old: ${oldStatus} &rarr; <strong> New: ${newStatus}</strong>`;

        }else if (key === 'remarks') {
            const formattedKey = 'Remarks';
            return `<strong>${formattedKey}:</strong> Old: ${oldValue} &rarr; <strong> New: ${newValue}</strong>`;

        }else if (key === 'status' && log_name === 'outreach_invoice') {
            const formattedKey = 'Status';

            const statusOptions = [
                { id: 0, name: "Pending" },
                { id: 1, name: "Awaiting Finance Approval" },
                { id: 2, name: "Paid" },
                { id: 3, name: "Rejected" },
            ];

            const oldStatus = statusOptions.find(option => option.id === oldValue)?.name || oldValue;
            const newStatus = statusOptions.find(option => option.id === newValue)?.name || newValue;

            return `<strong>${formattedKey}:</strong> Old: ${oldStatus} &rarr; <strong> New: ${newStatus}</strong>`;

        }
        else if (key === 'status') {
            const formattedKey = 'Status';

            const statusOptions = [
                { id: 0, name: "Pending" },
                { id: 1, name: "Approved" },
                { id: 2, name: "Rejected" },
                { id: 3, name: "Soft Reject" },
            ];

            const oldStatus = statusOptions.find(option => option.id === oldValue)?.name || oldValue;
            const newStatus = statusOptions.find(option => option.id === newValue)?.name || newValue;

            return `<strong>${formattedKey}:</strong> Old: ${oldStatus} &rarr; <strong> New: ${newStatus}</strong>`;

        }else if (key === 'link_insertion_price') {
            const formattedKey = 'Link Insertion Price';
            return `<strong>${formattedKey}:</strong> Old: ${oldValue} &rarr; <strong> New: ${newValue}</strong>`;
        }
        else if (key === 'link_insertion_price_currency') {
            const formattedKey = 'Link Insertion Price Currency';

            const oldCurrency = this.filteredCurrencyOptions.find(item => item.id === oldValue)?.currency || 'Unknown';
            const newCurrency = this.filteredCurrencyOptions.find(item => item.id === newValue)?.currency || 'Unknown';

            return `<strong>${formattedKey}:</strong> Old: ${oldCurrency} &rarr; <strong> New: ${newCurrency}</strong>`;
            
        }
        else if (key === 'cost_price') {
            const formattedKey = 'Cost Price';
            return `<strong>${formattedKey}:</strong> Old: ${oldValue} &rarr; <strong> New: ${newValue}</strong>`;

        }else if (key === 'backlink_url') {
            const formattedKey = 'Backlink URL';
            return `<strong>${formattedKey}:</strong> Old: ${oldValue} &rarr; <strong> New: ${newValue}</strong>`;

        }else if (key === 'site_list_id') {
            const formattedKey = 'Site List ID';
            return `<strong>${formattedKey}:</strong> has changed`;
        }else if (key === 'targeted_url') {
            const formattedKey = 'Targeted URL';
            return `<strong>${formattedKey}:</strong> Old: ${oldValue} &rarr; <strong> New: ${newValue}</strong>`;
            
        }else if (key === 'backlinks_type') {
            const formattedKey = 'Backlinks Type';

            const backlinkTypeOptions = [
                { id: 0, name: "Guest Post" },
                { id: 1, name: "Link Insertion" },
            ];

            const oldStatus = backlinkTypeOptions.find(option => option.id === oldValue)?.name || oldValue;
            const newStatus = backlinkTypeOptions.find(option => option.id === newValue)?.name || newValue;

            return `<strong>${formattedKey}:</strong> Old: ${oldStatus} &rarr; <strong> New: ${newStatus}</strong>`;
            
        }else if (key === 'published_date') {
            const formattedKey = 'Published Date';
            return `<strong>${formattedKey}:</strong> Old: ${oldValue} &rarr; <strong> New: ${newValue}</strong>`;
        }else if (key === 'cost_price_currency') {
            const formattedKey = 'Cost Price Currency';

            const oldCurrency = this.filteredCurrencyOptions.find(item => item.id === oldValue)?.currency || 'Unknown';
            const newCurrency = this.filteredCurrencyOptions.find(item => item.id === newValue)?.currency || 'Unknown';

            return `<strong>${formattedKey}:</strong> Old: ${oldCurrency} &rarr; <strong> New: ${newCurrency}</strong>`;
            
        }else if (key === 'approver_id') {
            const formattedKey = 'Approver';
            return `<strong>${formattedKey}</strong> has changed`;
            
        }else if (key === 'invoice_date') {
            const formattedKey = 'Invoice Date';
            return `<strong>${formattedKey}:</strong> Old: ${oldValue} &rarr; <strong> New: ${newValue}</strong>`;
        }else if (key === 'payment_date') {
            const formattedKey = 'Payment Date';
            return `<strong>${formattedKey}:</strong> Old: ${oldValue} &rarr; <strong> New: ${newValue}</strong>`;

        }else if (key === 'payment_method' && log_name === 'outreach_invoice') {
            const formattedKey = 'Payment Method';

            const statusOptions = [
                { id: 0, name: "Not Set" },
                { id: 1, name: "Bank Transfer" },
                { id: 2, name: "PayPal" },
                { id: 3, name: "Credit Card" },
                { id: 4, name: "Bkash" },
                { id: 5, name: "Nagadh" },
                { id: 6, name: "Rocket" },
            ];

            const oldStatus = statusOptions.find(option => option.id === oldValue)?.name || oldValue;
            const newStatus = statusOptions.find(option => option.id === newValue)?.name || newValue;

            return `<strong>${formattedKey}:</strong> Old: ${oldStatus} &rarr; <strong> New: ${newStatus}</strong>`;

        }else if (key === 'payment_details') {
            const formattedKey = 'Payment Details';
            return `<strong>${formattedKey}:</strong> Old: ${oldValue} &rarr; <strong> New: ${newValue}</strong>`;
            
        }
    },

    async getOutreachNicheItems(params) {
        return await this.$api.get("api/outreach/log", {
            params: {
                filterSelectEmployeeId: this.filterSelectEmployeeById,
                filterSeller: this.filterSeller,
                filterSiteList: this.filterSiteList,
            },
        });
      },

        async loadItems() {

        try {
          const outreachNiche = await this.getOutreachNicheItems();
  
          const data = outreachNiche?.data?.data;
          this.rows = data;

        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Warning",
              icon: "BellIcon",
              variant: "warning",
              text: error?.response?.data?.message,
            },
          });
        }
      },


    },
  }
  </script>